import React from 'react';
import { SelectedFilters as ReactiveSelectedFilters } from '@appbaseio/reactivesearch';
import { styled } from '@mui/material/styles';

type IReactiveSelectedFiltersProps = React.ComponentProps<
  typeof ReactiveSelectedFilters
>;
interface ISelectedFiltersProps
  extends Omit<IReactiveSelectedFiltersProps, 'showClearAll'> {
}

const StyledReactiveSelectedFilters = styled(ReactiveSelectedFilters)(({ theme }) => ({
  display: 'inline',
  '& a': {
    backgroundColor: theme.palette.grey['200'],
    color: theme.palette.text.primary,
    '& span:nth-of-type(2)': {
      borderLeft: '1px solid #fff !important',
    },
  }
})) as unknown as typeof ReactiveSelectedFilters;

class SelectedFilters extends React.Component<
  ISelectedFiltersProps
> {
  wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  addAria = () => {
    const wrapper = this.wrapperRef.current;
    if (wrapper) {
      const links = Array.from(wrapper.querySelectorAll('a'));
      for (const link of links) {
        this.addAriaToFilterLink(link);
      }
    }
  };
  addAriaToFilterLink = (link: HTMLAnchorElement) => {
    link.setAttribute('role', 'button');
    const label = link.textContent;
    if (label) {
      const labelNoX = label.slice(0, -1);
      const ariaLabel = `Remove filter (${labelNoX})`;
      link.setAttribute('aria-label', ariaLabel);
    }
  };


  render() {
    const {
      innerClass,
      className,
      onChange,
      ...otherProps
    } = this.props;
    return (
      <div ref={this.wrapperRef}>
        <StyledReactiveSelectedFilters
          onChange={() => {
            this.addAria();
            if (onChange) {
              onChange();
            }
          }}
          showClearAll={true}
          {...otherProps}
        />
      </div>
    );
  }
}

export default SelectedFilters;
